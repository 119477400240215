import moment from "moment";

export function formatDate(dt: Date | string, format?: string): string {
    if (dt == null) return "";
    return moment(dt).format(format ?? "MM/DD/YY");
}

export function formatUTCDate(dt: Date | string, format?: string): string {
    if (dt == null) return "";
    return moment(dt).utc().format(format ?? "MM/DD/YY");
}

export function toUtcDay(dt: Date | string): Date {
    //input: 2022-05-01T00:00:00Z, when converted to date type it becomes "Sat Apr 30 2022 19:00:00 GMT-0500 (Central Daylight Time)". 
    //moment.utc.format gives 05/01/22 date using which date type is created to get 'Sun May 01 2022 00:00:00 GMT-0500 (Central Daylight Time)'
    if (dt == null) return null;
    return moment(moment.utc(dt).format("MM/DD/YY")).toDate();
}

export function formatRecentDate(dt: Date): string {
    const m = moment(dt);

    let format = "h:mm a";
    const now = moment();
    if (now.diff(m, 'days') > 0) {
        if (now.year() !== m.year()) {
            format = "MMM D YYYY";
        }
        else {
            format = "MMM D";
        }
    }

    return m.format(format);
}

export function getNextDay(): Date {
    return moment().add(1, 'days').toDate();
}

export function getNextDayForGivenDate(date: Date): Date {
    return moment(date)?.add(1, 'days').toDate();
}

export function getPreviousDay(): Date {
    return moment().subtract(1, 'days').toDate();
}

export function getPreviousDayForGivenDate(date: Date): Date {
    if (date == null) return null;

    return moment(date)?.subtract(1, 'days').toDate();
}

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function getMonthName(month: number) {
    if (month < 0 || month > 12) return "Invalid month";

    return monthNames[month - 1];
}

export function getShortMonthName(month: number) {
    return getMonthName(month).slice(0, 3);
}

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export function getDayName(dayOfWeek: number) {
    if (dayOfWeek < 0 || dayOfWeek >= dayNames.length) return "Invalid day of week";

    return dayNames[dayOfWeek];
}

export function getUTCDayStartDateString(dt: Date): string {
    return formatDate(dt, "YYYY-MM-DD") + "T00:00:00Z";
}

export function getLastMonthEndDate(date: Date): Date {
    var dt = moment(date).startOf('month').subtract(1, 'days').toDate();
    return dt;
}

export function isWeekDay(date: Date): boolean {
    return moment(date).day() >= 1 && moment(date).day() <= 5;
}

export function getNthLastWeekDay(date: Date, n: number): Date {
    while (n > 0) {
        const previousDay = getPreviousDayForGivenDate(date);
        if(isWeekDay(date)) {
            return (n - 1) === 0 ? date : getNthLastWeekDay(previousDay, n - 1);
        }
        else {
            return getNthLastWeekDay(previousDay, n);
        }
    }
}

export function getNthWeekDay(date: Date, n: number): Date {
    while (n > 0) {
        const nextDay = getNextDayForGivenDate(date);
        if(isWeekDay(date)) {
            return (n - 1) === 0 ? date : getNthWeekDay(nextDay, n - 1);
        }
        else {
            return getNthWeekDay(nextDay, n);
        }
    }
}

export function firstBusinessDayOfNextYear(): string {
    const dt = moment().add(1, 'years').startOf('year').add(1, 'day').toDate();
    return  formatDate(getNthWeekDay(dt, 1), 'yyyy-MM-DD');
}

export function getTodaysDate(): Date {
    return moment().toDate();
}

export function formatTodaysDate(format?: string): string {
    return moment().format(format ?? "MM/DD/YY")
}