import { PositionSetting } from "domain/positionSetting";
import PositionSettingConfiguration from "./positionSettingConfiguration";
import Throttle from "common/decorators/throttle";
import HttpService from "common/http/httpService";
import PatchOperation, { OperationType } from "common/http/patchOperation";

class PositionSettingsService extends HttpService {
    async updateAutoPosition(positionSettingId: string, isEnabled: boolean) {
        const operations: PatchOperation[] = [
            new PatchOperation(OperationType.Replace, "/isAutoPositionDisabled", !isEnabled)
        ];

         await this.getClient().patch(`/settings/v1/position-settings/${positionSettingId}`, operations);
    }
    
    async updateAutoPositionForCommodity(commodityId: number, positionGroupId: number, isEnabled: boolean) {
        await this.getClient().put(`/settings/v1/commodities/${commodityId}/position-groups`,
            {
                positionGroupId,
                isAutoPositionDisabled: !isEnabled
            }
        );
    }
    
    @Throttle(1000, true)
    async listPositionSettings(): Promise<PositionSetting[]> {
        const response = await this.getClient().get<PositionSetting[]>('/settings/v1/position-settings');
        return response.data;
    }

    async getPositionSettingConfiguration(): Promise<PositionSettingConfiguration> {
        const response = await this.getClient().get<PositionSettingConfiguration>(
            "/settings/v1/configuration/position-settings"
        );
        return response.data;
    } 
    
    async addPositionSetting(positionSetting: PositionSetting): Promise<PositionSetting> {
        const createRequest = {
            displayName: positionSetting.displayName,
            commodityId: positionSetting.commodity.id,
            futuresContractId: positionSetting.contract.id,
            fcmAccountId: positionSetting.fcmAccount.id,
            cropYearId: positionSetting.cropYear.id,
            defaultLocationId: positionSetting.defaultLocation?.id,
            targetBalance: positionSetting.targetBalance,
            fullContractMinimum: positionSetting.fullContractMinimum,
            miniContractMinimum: positionSetting.miniContractMinimum,
            useLimitOrder: positionSetting.useLimitOrder,
            autoOrderWillSell: positionSetting.autoOrderWillSell,
            positionGroupId: positionSetting.positionGroup?.id
        }
        const response = await this.getClient().post<PositionSetting>(
            '/settings/v1/position-settings', createRequest
        );
        return response.data;
    }

    async updatePositionSetting(positionSetting: PositionSetting): Promise<any> {
        const updateRequest = {
            displayName: positionSetting.displayName,
            futuresContractId: positionSetting.contract?.id,
            fcmAccountId: positionSetting.fcmAccount?.id,
            targetBalance: positionSetting.targetBalance,
            fullContractMinimum: positionSetting.fullContractMinimum,
            miniContractMinimum: positionSetting.miniContractMinimum,
            useLimitOrder: positionSetting.useLimitOrder,
            autoOrderWillSell: positionSetting.autoOrderWillSell,
            cropYearId: positionSetting.cropYear.id,
            commodityId: positionSetting.commodity.id,
            defaultLocationId: positionSetting.defaultLocation?.id,
            positionGroupId: positionSetting.positionGroup?.id,
            isCashOnly: positionSetting.commodity.isCashOnly
        }
        await this.getClient().put(
            `/settings/v1/position-settings/${positionSetting.id}`, updateRequest
        );
    }

   async deletePositionSetting(position: PositionSetting): Promise<any> {
       await this.getClient().delete(`/settings/v1/position-settings/${position.id}`);
    }

}

const positionSettingService = new PositionSettingsService();
export default positionSettingService;
